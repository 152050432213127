import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Table, Alert, Card, Input, Button, Space } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import "antd/dist/antd.css";
import * as common from "./Common";


const Ipo = ({ resetResult, }) => {
  useEffect(() => {
  }, [])

  const [post, setPost] = React.useState(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchedColumn, setSearchedColumn] = React.useState(null);
  const [state, setState] = useState({searchText:'', searchedColumn:''})
  
  const [sortOrder, setSortOrder] = useState('ascend');
  const [sortedColumn, setSortedColumn] = useState(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(confirm, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select()
        )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn:dataIndex,
    });
  };
  
  const handleReset = (confirm, clearFilters) => {
    clearFilters();
    setState({searchText: ''});
    confirm();
  };
  
  React.useEffect(() => {
    axios.get("/api/v3/stock/ipo").then((response) => {
      //console.log(response.data);
      setPost(response.data);
    });
  }, []);

  //if (!post) return null;

  const columns = [
    {
      title: '종목',
      dataIndex: 'name',
      fixed: 'left',
      ...getColumnSearchProps('name'),
      render: (text, record) => (renderName(text, record))
    },
    {
      title: '코드',
      dataIndex: 'code',
      ...getColumnSearchProps('code'),
      width:80,
    },
    {
      title: '일자',
      dataIndex: 'dt',
      width:80,
    },
    {
      title: '거래소',
      dataIndex: 'gubun',
      width:60,
    },
    {
      title: '업종',
      dataIndex: 'upjong',
    },
    {
      title: '상태',
      dataIndex: 'status',
      width:90,
    },
    {
      title: '주관사',
      dataIndex: 'organizer',
    },
    {
      title: '예상가',
      dataIndex: 'price',
      align: 'right',
    },
    {
      title: '청약일',
      dataIndex: 'spersonday',
      render: (text, record) => (renderPersonDay(text, record)),
      sorter: (a, b) => {
         // "미정" 값을 처리하여 숫자로 변환
        const numA = a.spersonday === '미정' ? Infinity : parseInt(a.spersonday, 10);
        const numB = b.spersonday === '미정' ? Infinity : parseInt(b.spersonday, 10);

        //console.log(sortOrder, numA, numB);

        // 현재 정렬 방향에 따라 다르게 정렬
        if (sortOrder === 'descend') {
          return numB - numA; // 내림차순 정렬
        } else {
          return numA - numB; // 오름차순 정렬
        }
      },
      sortOrder: sortedColumn === 'spersonday' && sortOrder,
      sortDirections: ['ascend'],
      align: 'right',
      onHeaderCell: (column) => ({
        onClick: () => handleSortChange('spersonday'), // 클릭 시 정렬 방향 변경
      }),
    },
    {
      title: '상장일',
      dataIndex: 'openday',
      align: 'right',
    },
  ];
  
  // 정렬 방향 변경 함수
  const handleSortChange = (column) => {
    const newSortOrder = sortOrder === 'descend' ? 'ascend' : 'descend';
    setSortOrder(newSortOrder);
    setSortedColumn(column);
  };

  function renderName(text, record) {
    if (record.url) {
      return <a href={'http://'+record.url} target='_blank' rel='noopener noreferrer nofollow'>{text}</a>
    }
    else {
      return <a href={'https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query='+text} target='_blank' rel='noopener noreferrer nofollow'>{text}</a>
    }
    //else{
    //  return <span >{text}</span>
    //}
  }

  function renderPersonDay(text, record) {
    if (!record.spersonday || record.spersonday.includes('미정')) {
      return <span>미정</span>
    } else {
      return <span>{common.to_ymd_divid(record.spersonday)}~{common.to_ymd_divid(record.epersonday)}</span>
    }
  }
  
  return (
    <div>
      <Alert 
              closable
              style={{ textAlign: "left" }}
              type="info" showIcon 
              message={[
                "IPO 공모주 현황입니다",
                <br/>,
                "홈페이지를 찾은 경우 종목명에 링크가 활성화됩니다. 홉페이지를 못찾은 경우는 네이버 검색으로 이동합니다.",
                <br/>,
                "상태는 [예심청구 > 심사승인 > 수요예측 > 공모청약] 순으로 진행됩니다"
                ]}/>
       <Card title="IPO" size="small" bordered={true}>
          <Table
            bordered
            size="small" scroll={{ x: 1024 }}
            rowKey={"code"}
            dataSource={post}
            columns={columns}
            pagination={{ defaultPageSize: common.DEFAULT_PAGE }}
          />
        </Card>
    </div>
  )
}

export default Ipo;
