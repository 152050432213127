import create from 'zustand'
import { Tag, Tooltip, Table, Alert, Card, Input, Button, Space } from 'antd';
import React, { Component, useState, useEffect , useRef } from 'react';
import {isMobile} from "react-device-detect"

export const DEFAULT_PAGE=25;

export const market_filters = [ 
  {text: "KS", value:"0"}, 
  {text: "KQ", value:"1"}, 
];

export const state_filters = [ 
  {text: "대기", value:"0"}, 
  {text: "보유", value:"1"}, 
];

export const gubun_filters = [ 
  {text: "대기", value:"0"}, 
  {text: "매수", value:"1"}, 
  {text: "보유", value:"2"}, 
  {text: "매도", value:"3"}, 
  {text: "급매", value:"4"}, 
  {text: "청산", value:"5"}, 
  {text: "손절", value:"6"},   
];


const useStore = create (() => ({
  navidx: 0,
}))

export function changeColor(text, gubun) {
  //console.log(text, gubun);
  var val = parseFloat(text)
  if (val > 0) {
    return <span style={{ color: 'red' }}>{text}</span>
  }
  else if (val < 0) {
    return <span style={{ color: 'blue' }}>{text}</span>
  }
  else {
    return <span >{text}</span>
  }
}

export function renderCode(code) {
  if (isMobile) {
    return <a href={`https://m.stock.naver.com/fchart/domestic/stock/` + code} target='_blank' rel='noopener noreferrer nofollow'>{code}</a>
  } else {
    return <a href={`https://finance.daum.net/chart/A` + code} target='_blank' rel='noopener noreferrer nofollow'>{code}</a>
  }
}

export function makeTableTitle(title, val) {
  // var star;
  // if (val === 1) star = "⭐"
  // else if (val === 2) star = "⭐⭐"
  // else if (val === 3) star = "⭐⭐⭐"
  // else if (val === 4) star = "⭐⭐⭐⭐"
  // else star = ""
  // return title + "  " + star;
  return title;
};

export function makeESearchTableText(title, val) {
  
  let arr = [];

  if (val.indexOf("MACD저항(일)") != -1) {
    arr.push({id:"M1",desc:"MACD저항(일)",color:"#2db7f5"});
  }
  if (val.indexOf("MACD저항(주)") != -1) {
    arr.push({id:"M5",desc:"MACD저항(주)",color:"#2db7f5"});
  }
  if (val.indexOf("STOC과매도(일)") != -1) {
    arr.push({id:"S1",desc:"STOC과매도(일)",color:"#FF8C00"});
  }
  if (val.indexOf("STOC과매도(주)") != -1) {
    arr.push({id:"S5",desc:"STOC과매도(주)",color:"#FF8C00"});
  }
  if (val.indexOf("장기추세(210)") != -1) {
    arr.push({id:"추세",desc:"장기추세(210)",color:"#87d068"});
  }
  if (val.indexOf("순이익흑전") != -1) {
    arr.push({id:"흑전",desc:"순이익흑전",color:"#87d068"});
  } 
  if (val.indexOf("성장주") != -1) {
    arr.push({id:"성장",desc:"성장주",color:"#87d068"});
  }
  if (val.indexOf("평균거래급증") != -1) {
    arr.push({id:"거래",desc:"평균거래급증",color:"#ff1493"});
  }
  if (val.indexOf("볼린저밴드하단근접") != -1) {
    arr.push({id:"B.B",desc:"볼린저밴드하단근접",color:"#ff1493"});
  }

  return arr.map(res => (
    <Tooltip placement="topLeft" mouseLeaveDelay="0.2" trigger="click|hover" title={res.desc}>
    <Tag color={res.color} style={{ margin: 1, fontSize: '5px', color: '#fff' }} key={res.id}> {res.id} </Tag>
    </Tooltip>
  ))
};

export function makeCustomRedColor(text) 
{
  var result;
  var code = parseInt(text);
  if(code <= 0) {
    code = code * -1;
    code=255-(code*2);
    var val = code.toString(16);
    result = '#'+ val + val  + 'ff';
  }
  else {
    code=255-(code*2);
    var val = code.toString(16);
    result = '#ff' + val  + val;
  }  
  return result;
}

export function toDateString(unixTime) {
  const dateObj = new Date(unixTime);

  // 날짜와 시간 값을 문자열로 변환
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const date = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  // 문자열을 결합하여 "y-m-d h:m:s" 형태로 표시
  const dateString = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  return dateString;
}

export function to_ymd_divid(dateString) {  
  if (dateString.length === 8) {
    const year = dateString.substr(0, 4);
    const month = dateString.substr(4, 2);
    const day = dateString.substr(6, 2);
    return `${year}/${month}/${day}`;
  }
  return dateString; // 다른 형식의 값이면 그대로 반환
} 