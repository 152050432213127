import React, { Component, useState, useEffect , useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Table, Alert, Card, Input, Button, Space } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import "antd/dist/antd.css";
import * as common from "./Common";


const Lab = ({ resetResult, }) => {
  useEffect(() => {
  }, [])

  let tblTitle = 'Interesting study Lab';
  const [post, setPost] = React.useState(null);

  const searchInput = useRef(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchedColumn, setSearchedColumn] = React.useState(null);
  const [state, setState] = useState({searchText:'', searchedColumn:''})

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(confirm, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select()
        )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn:dataIndex,
    });
  };
  
  const handleReset = (confirm, clearFilters) => {
    clearFilters();
    setState({searchText: ''});
    confirm();
  };

  React.useEffect(() => {
    axios.get("/api/v3/stock/lab").then((response) => {
      //console.log(response.data);
      setPost(response.data);
    });
  }, []);

  //if (!post) return null;

  const columns = [
    {
      title: "종목",
      dataIndex: "name",
      fixed: "left",
      key: "name",
      width:80,
      ...getColumnSearchProps('name'),
      render: (text, record) => <a href={`https://finance.naver.com/item/main.nhn?code=` + record.code} target='_blank' rel='noopener noreferrer nofollow'>{text}</a>
    },
    {
      title: "코드",
      dataIndex: "code",
      key: "code",
      width:70,
      ...getColumnSearchProps('code'),
      render: (text, record) => common.renderCode(text)
    },
    {
      title: "일시",
      dataIndex: "dt",
      width:70,
    },
    {
      title: "구분",
      dataIndex: "gubun",
      filters: [
        {
          text: "KQ",
          value: "KQ",
        },
        {
          text: "KS",
          value: "KS",
        },
      ],
      width:70,
      onFilter: (value, record) => record.gubun.indexOf(value) === 0,
    },
    {
      title: "목표주가",
      dataIndex: "destrate",
      width: 80,
      align: 'right',
      render: (text, record) => record.destprice ?
        record.destprice + '(' + record.destrate + '%)' :
        ''
    },
    {
      title: "거래량회전율",
      dataIndex: "pre_vol_rr",
      sorter: (a, b) => a.pre_vol_rr - b.pre_vol_rr,
      sortDirections: ['ascend','descend'],
      width:70,
      align: 'right',
    },
    {
      title: "거래대금회전율",
      dataIndex: "siga_trdval_rr",
      sorter: (a, b) => a.siga_trdval_rr - b.siga_trdval_rr,
      sortDirections: ['ascend','descend'],
      width:70,
      align: 'right',
    },
    {
      title: "기관매매대금",
      dataIndex: "ki_trdval",
      align: 'right',
      sorter: (a, b) => a.ki_trdval - b.ki_trdval,
      sortDirections: ['ascend','descend'],
      width:70,
      render(text, record) {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    {
      title: "외인매매대금",
      dataIndex: "fo_trdval",
      sorter: (a, b) => a.fo_trdval - b.fo_trdval,
      sortDirections: ['ascend','descend'],
      width:70,
      align: 'right',
      render(text, record) {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    {
      title: "재무점수",
      dataIndex: "j_score",
      sorter: (a, b) => a.j_score - b.j_score,
      sortDirections: ['ascend','descend'],
      width:70,
      align: 'right',
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text)}
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "실험점수",
      dataIndex: "l_score",
      sorter: (a, b) => a.l_score - b.l_score,
      sortDirections: ['ascend','descend'],
      width:70,
      align: 'right',
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text)}
          },
          children: <div>{text}</div>
        };
      }
    },
  ];

  function setIcon(b) {
    return <div>
      {b ?
        <Tag color='volcano'>detect</Tag> : ""
      }
    </div>
  };

  function makeTableTitle() {
    return tblTitle;
  };


  function showExpendedRow(record) {
    return <p style={{ margin: -5 }}> <b>[업종] </b>{record.upjong} <b>[테마] </b>{record.theme}<br/>{record.summary}</p>
  }

  return (
    <div>
      <Alert 
              closable
              style={{ textAlign: "left" }}
              type="info" showIcon 
              message={[
              "거래량회전율 : 전일 거래량 대비 거래량 회전율",
              <br/>,
              "거래대금회전율 : 시가총액 대비 거래대금 회전율",
              <br/>,
              "기관매매대금 & 외인매매대금: 투자자별 순매매량에 종가를 곱한 금액",
              <br/>,
              "각 항목별 석차를 구한 다음 평균을 내어 실험점수를 산출하고 내림차순으로 보여줍니다"
              ]}/>
       <Card title={makeTableTitle()} size="small" bordered={true}>
          <Table
            bordered
            size="small" scroll={{ x: 1024 }}
            rowKey={"code"}
            dataSource={post}
            columns={columns}
            expandable={{ expandedRowRender: record => 
              showExpendedRow(record),}}
            pagination={{ defaultPageSize: 50 }}
          />
        </Card>
    </div>
  )
}

export default Lab;
