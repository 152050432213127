import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Table, Alert, Select, Card, Input, Button, Space, Tooltip } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import "antd/dist/antd.css";
import * as common from "./Common";

const { Option } = Select;

const Cond = ({ resetResult, }) => {
  useEffect(() => {
  }, [])

  const [selected, setSelected] = React.useState("");
  const [dt, setDt] = React.useState(null);
  const [slist, setSList] = React.useState(null);
  const [grp, setGrp] = React.useState(null);
  const [rel, setRel] = React.useState(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchedColumn, setSearchedColumn] = React.useState(null);
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })

  var inquerys = null;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(confirm, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select()
        )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (confirm, clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
    confirm();
  };

  function contactUpjonTheme(record) {
    return (<div> {record.upjong} <br /> {record.theme} </div>)
  }

  React.useEffect(() => {
    axios.get("/api/v3/stock/esearch").then((response) => {
      //console.log(response.data);
      if(response.data) {
        setGrp(response.data.grp);
        setRel(response.data.rel);
        setSList(response.data.list);
        setDt(response.data.dt);
      } else {
        setGrp(null);
        setSList(null);
        setDt(null);
      }
    });
  }, []);

  //if (!post) return null;

  const columns = [
    {
      title: "종목",
      dataIndex: "name",
      fixed: "left",
      key: "name",
      width: 120,
      ...getColumnSearchProps('name'),
      render: (text, record) => <a href={`https://finance.naver.com/item/main.nhn?code=` + record.code} target='_blank' rel='noopener noreferrer nofollow'> {common.makeTableTitle(text, record.sum)}</a>
    },
    {
      title: "코드",
      dataIndex: "code",
      key: "code",
      width: 70,
      ...getColumnSearchProps('code'),
      render: (text, record) => common.renderCode(text)
    },
    {
      title: "업종/테마",
      dataIndex: "upjong",
      key: "upjong",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" mouseLeaveDelay="0.3" trigger="click|hover" title={contactUpjonTheme(record)}>
          {record.upjong}
        </Tooltip>
      ),
      width: 140,
    },
    {
      title: "구분",
      dataIndex: "gubun",
      filters: [
        {
          text: "KQ",
          value: "KQ",
        },
        {
          text: "KS",
          value: "KS",
        },
      ],
      width: 70,
      onFilter: (value, record) => record.gubun.indexOf(value) === 0,
    },
    {
      title: "목표주가",
      dataIndex: "destrate",
      align: 'right',
      width: 100,
      render: (text, record) => record.destprice ?
        record.destprice + '(' + record.destrate + '%)' :
        ''
    },
    {
      title: "등락률",
      dataIndex: "diff",
      align: 'right',
      render: (text, record) => (common.changeColor(text, record.sign)),
      width: 70,
    },
    {
      title: "현재가",
      dataIndex: "price",
      align: 'right',
      width: 70,
    },
    {
      title: 'PER',
      dataIndex: 'per',
      align: 'right',
      sorter: (a, b) => a.per - b.per,
      sortDirections: ['ascend', 'descend'],
      width: 70,
      render: (text, record) => record.per_toupjong ? <span> {record.per}/{record.per_toupjong} </span> : <span>{record.per}</span>
    },
    {
      title: 'PBR',
      dataIndex: 'pbr',
      align: 'right',
      sorter: (a, b) => a.pbr - b.pbr,
      sortDirections: ['ascend', 'descend'],
      width: 70,
      render: (text, record) => record.pbr_toupjong ? <span> {record.pbr}/{record.pbr_toupjong} </span> : <span>{record.pbr}</span>
    },
    {
      title: 'ROE',
      dataIndex: 'roe',
      align: 'right',
      sorter: (a, b) => a.roe - b.roe,
      sortDirections: ['ascend', 'descend'],
      width: 70,
      render: (text, record) => record.roe_toupjong ? <span> {record.roe}/{record.roe_toupjong} </span> : <span>{record.roe}</span>
    },
    {
      title: 'ROA',
      dataIndex: 'roa',
      align: 'right',
      sorter: (a, b) => a.roa - b.roa,
      sortDirections: ['ascend', 'descend'],
      width: 70,
      render: (text, record) => record.roa_toupjong ? <span> {record.roa}/{record.roa_toupjong} </span> : <span>{record.roa}</span>
    },
    {
      title: '발견수',
      dataIndex: 'cnt',
      align: 'right',
      sorter: (a, b) => a.cnt - b.cnt,
      sortDirections: ['ascend', 'descend'],
      width: 70,
    },
    {
      title: "실험점수",
      dataIndex: "l_score",
      sorter: (a, b) => a.l_score - b.l_score,
      sortDirections: ['ascend', 'descend'],
      width: 70,
      align: 'right',
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text) }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "재무점수",
      dataIndex: "j_score",
      align: 'right',
      width: 70,
      sorter: (a, b) => a.j_score - b.j_score,
      sortDirections: ['ascend', 'descend'],
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text) }
          },
          children: <div>{text}</div>
        };
      }
    },
  ];

  const handleSelectChange = (value) => {
    console.log("handleSelectChange:", value);
    setSelected(value);
  }

  function makeCondTitle(dt) {
    return "Cond Search [" + dt + "]";
  }

  const IterSelectCond = value => {
    //console.log('셀렉트 IterCond:', value.dataSource);
    console.log(value);
    const arr = value.dataSource;
    if (arr == null) return null;
    const condList = arr.map((cond) => <Option key={cond.queryindex} value={cond.queryindex}>{cond.queryname}</Option>)

    return (
      <Select
        style={{ width: 200 }}
        onChange={handleSelectChange}
        value={selected}
      >
        {condList}
      </Select>
    );
  }

  if (!selected) {
    setSelected("0");
  }

  if (selected === "0") {
    if(slist) {
      inquerys = slist.filter(data => {
        return data;
      });
    }
  }
  else {
    var result = [];
    if(slist) {
      slist.filter(data => {
        rel.filter(item => {
          if (data.code === item.code && item.queryindex === selected) {
            //console.log('selected:', selected, data.code, item.code);
            result.push(data);      
          }
        });
      });
      inquerys = result;
    } else {
      inquerys = null;
    }    
  }

  function showExpendedRow(record) {
    return <p style={{ margin: -5 }}> <b>[업종] </b>{record.upjong} <b>[테마] </b>{record.theme}<br />{record.summary}</p>
  }

  return (
    <div>
      <Alert closable
        type="info"
        showIcon
        style={{ textAlign: "left" }}
        message={["조건 검색식으로 검출된 종목 현황입니다. 조건 검색식은 새롭게 추가/변경/삭제 될 수 있습니다.",
          <br />,
          "조건검색식 기준에 대한 상세 설명은 ", <a href="https://ososoi.tistory.com/357?category=915962"> 여기 </a>, "에서 확인 가능합니다.",
          <br />,
          "'종목명'을 클릭하면 Naver증권으로, '종목코드'를 클릭하면 다음차트(모바일에선 네이버차트)로 이동합니다."
        ]}
      />
      <Card title={dt !== null ? makeCondTitle(dt[0].dt) : makeCondTitle("")} size="small" bordered={true}
        extra={
          <IterSelectCond dataSource={grp !== null ? grp : null} />
        }
      >
        <Table
          bordered
          size="small" scroll={{ x: 1024 }}
          rowKey={"code"}
          dataSource={inquerys}
          columns={columns}
          expandable={{
            expandedRowRender: record =>
              showExpendedRow(record),
          }}
          pagination={{ defaultPageSize: common.DEFAULT_PAGE }}
        />
      </Card>

    </div>
  )
}

export default Cond;
