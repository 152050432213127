import React from 'react';
import Iframe from 'react-iframe'

const GuestBook = () => {
  return <Iframe url="https://ososoi.tistory.com/guestbook"
    width="100%"
    height="1080px"
    id="myId"
    className="myClassname"
    display="inline"
    position="relative" 
    frameBorder="0"/>
}

export default GuestBook;