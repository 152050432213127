import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Table, Alert, Card, Input, Button, Space } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import "antd/dist/antd.css";
import * as common from "./Common";


const Account = ({ resetResult, }) => {
    useEffect(() => {
    }, [])

    const [post, setPost] = React.useState(null);

    const searchInput = useRef(null);
    const [searchText, setSearchText] = React.useState(null);
    const [searchedColumn, setSearchedColumn] = React.useState(null);
    const [state, setState] = useState({searchText:'', searchedColumn:''})
  
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(confirm, clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.current && searchInput.current.select()
          )
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setState({
        searchText: selectedKeys[0],
        searchedColumn:dataIndex,
      });
    };
    
    const handleReset = (confirm, clearFilters) => {
      clearFilters();
      setState({searchText: ''});
      confirm();
    };
    
    React.useEffect(() => {
        axios.get("/api/v3/stock/account").then((response) => {
            console.log(response.data);
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    const columns = [
        {
            title: "종목",
            dataIndex: "name",
            fixed: "left",
            key: "name",
            width: 120,
            ...getColumnSearchProps('name'),
            render: (text, record) => <a href={`https://finance.naver.com/item/main.nhn?code=` + record.code} target='_blank' rel='noopener noreferrer nofollow'>{text}</a>
        },
        {
            title: "코드",
            dataIndex: "code",
            key: "code",
            width: 70,
            ...getColumnSearchProps('code'),
            render: (text, record) => common.renderCode(text)
        },
        {
            title: "일시",
            dataIndex: "dt",
            width: 70,
        },
        {
            title: "구분",
            dataIndex: "gubun",
            filters: [
                {
                    text: "KQ",
                    value: "KQ",
                },
                {
                    text: "KS",
                    value: "KS",
                },
            ],
            width: 70,
            onFilter: (value, record) => record.gubun.indexOf(value) === 0,
        },
        {
            title: '매입가',
            dataIndex: 'buyval',
            width: 70,
        },
        {
            title: '현재가',
            dataIndex: 'curval',
            width: 70,
        },
        {
            title: '주당손익',
            dataIndex: 'diff',
            render: (text, record) => (common.changeColor(text, record.diff)),
            width: 70,
        },
        {
            title: '보유일',
            dataIndex: 'havedays',
            width: 70,
        },
        {
            title: '수익률',
            dataIndex: 'earnrate',
            sorter: (a, b) => a.earnrate - b.earnrate,
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => (common.changeColor(text, record.earnrate)),
            width: 70,
        },
        {
            title: "SCORE",
            dataIndex: "score",
            sorter: (a, b) => a.rank - b.rank,
            sortDirections: ['descend'],
            width: 70,
        },
    ];

    return (
        <div>
          <Alert closable message="모의투자 종목 현황입니다." type="info" showIcon />
            <Card title="Simulated Investment" size="small" bordered={true}>
                <Table
                    bordered
                    size="small" scroll={{ x: 1024 }}
                    rowKey={"code"}
                    dataSource={post}
                    columns={columns}
                    pagination={{ defaultPageSize: common.DEFAULT_PAGE }}
                />
            </Card>
        </div>
    )
}

export default Account;
