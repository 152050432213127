import React, { Component, useState, useEffect, useRef } from 'react';
import { Radio, Table, Alert, Card, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import "antd/dist/antd.css";
import moment from 'moment';
import * as common from "./Common";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  AreaChart,
  Area,
  Bar,
  Legend,
  ComposedChart,
  ReferenceLine,
} from 'recharts';

////////////////////////////////////////////////////////////////////////
const UpJong = ({ resetResult, }) => {
  useEffect(() => {
  }, [])

  const [post, setPost] = React.useState(null);
  const [alldata, setAllData] = React.useState(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchedColumn, setSearchedColumn] = React.useState(null);
  const [state, setState] = useState({ searchText: '', searchedColumn: '' })

  const getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(confirm, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select()
        )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (confirm, clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
    confirm();
  };

  React.useEffect(() => {
    axios.get("/api/v3/stock/upjong").then((response) => {
      //console.log(response.data);
      setPost(response.data);
    });

    axios.get("/api/v3/stock/upjongchart").then((response) => {
      setAllData(response.data);
    });

  }, []);

  const columns = [
    {
      title: '업종',
      dataIndex: 'z_name',
      key: "z_name",
      fixed: "left",
      width: 100,
      ...getColumnSearchProps('z_name'),
      render: (text, record) => <a href={record.z_suburl} target='_blank' rel='noopener noreferrer nofollow'>{text}</a>
    },
    {
      title: '시작일 기준',
      children: [
        {
          title: "시작일",
          dataIndex: "s_dt",
          width: 70,
          render: (text, record) => {
            const d = new Date(text);
            return moment(d).format('YYYY/MM/DD');
          }
        },
        {
          title: '전일대비(%)',
          dataIndex: 's_diff',
          align: 'right',
          sorter: (a, b) => a.s_diff - b.s_diff,
          sortDirections: ['descend', 'ascend'],
          width: 70,
        },
        {
          title: '누적변동률(%)',
          dataIndex: 's_cumrate',
          align: 'right',
          sorter: (a, b) => a.s_cumrate - b.s_cumrate,
          sortDirections: ['descend', 'ascend'],
          width: 70,
        },
      ],
    },    
    {
      title: '종료일 기준',
      children: [
        {
          title: "종료일",
          dataIndex: "e_dt",
          width: 70,
          render: (text, record) => {
            const d = new Date(text);
            return moment(d).format('YYYY/MM/DD');
          }
        },
        {
          title: '전일대비(%)',
          dataIndex: 'e_diff',
          align: 'right',
          sorter: (a, b) => a.e_diff - b.e_diff,
          sortDirections: ['descend', 'ascend'],
          width: 70,
        },
        {
          title: '누적변동률(%)',
          dataIndex: 'e_cumrate',
          align: 'right',
          sorter: (a, b) => a.e_cumrate - b.e_cumrate,
          sortDirections: ['descend', 'ascend'],
          width: 70,
        },
      ],
    },
    {
      title: '총기간',
      dataIndex: 'z_period',
      align: 'right',
      sorter: (a, b) => a.z_period - b.z_period,
      sortDirections: ['descend', 'ascend'],
      width: 70,
      render: (text, record) => {
        var days = text / 86400 / 1000;

        return days + ' days';
      }
    },
    {
      title: '기간내상승률(%)',
      dataIndex: 'z_varirate',
      align: 'right',
      sorter: (a, b) => a.z_varirate - b.z_varirate,
      sortDirections: ['descend', 'ascend'],
      width: 70,
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text) }
          },
          children: <div>{text}</div>
        };
      }
    },
  ];

  //if (!post) return null;

  function makeTableTitle() {
    return "UpJong Volatility Rate Rank";
  }

  function showExpendedRow(record) {
    if (alldata === null)
      return <div><p style={{ margin: -5 }}> <b>[업종차트] </b></p></div>;

    const filtered = alldata.filter(data => data.z_name === record.z_name);
    if (filtered === null)
      return <div><p style={{ margin: -5 }}> <b>[업종차트] </b></p></div>;

    return (
      <div>
        <p style={{ margin: -5 }}> <b>[업종차트] </b></p>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={filtered}
            syncId="anyId"
            margin={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="2 2" />
            <XAxis dataKey="z_dt" angle={-20} textAnchor="end" fontSize="10" />
            <YAxis yAxisId="left-axis" fontSize="10" type="number" />
            <YAxis yAxisId="right-axis" orientation="right" fontSize="10" type="number"  />
            <Legend layout="horizontal" verticalAlign="top" align="right" wrapperStyle={{top: -5, left: 0}} />
            <Tooltip />
            <Bar yAxisId="left-axis" dataKey="macdo" barSize='3' fill="#82ca9d" />
            <Line yAxisId="left-axis" type="monotone" dataKey="macd" stroke="#8884d8" dot={false} />
            <Line yAxisId="left-axis" type="monotone" dataKey="macds" stroke="#ffa500" dot={false} />
            <Line yAxisId="right-axis" type="monotone" dataKey="누적변동률" stroke="#fb122f" strokeWidth={2} dot={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return (
    <div>
      <Alert closable
        type="info"
        showIcon
        style={{ textAlign: "left" }}
        message={["업종별 변동률 현황",
          <br />,
        ]}
      />
      <Card title={makeTableTitle()} size="small" bordered={true}
      >
      <Table
        bordered
        size="small" scroll={{ x: 1024 }}
        rowKey={'z_name'}
        dataSource={post}
        columns={columns}
        expandable={{
          expandedRowRender: record =>
            showExpendedRow(record),
        }}
        pagination={{ defaultPageSize: common.DEFAULT_PAGE }}
      />
      </Card>
    </div>
  )
}

export default UpJong;
