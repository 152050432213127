import React from 'react';
import { Route, Link, Routes } from "react-router-dom";
import 'antd/dist/antd.css';
import './index.css';
import { Layout, Menu, } from 'antd';
import Lab from './components/Lab';
import Jemu from './components/Jemu';
import Cond from './components/Cond';
import Account from './components/Account';
import GuestBook from './components/GuestBook';
import Readme from './components/Readme';
import Keyword from './components/Keyword';
import UpJong from './components/UpJong';
import Theme from './components/Theme';
import ETrade from './components/ETrade';
import Ipo from './components/Ipo';

const { Content, Footer } = Layout;

const sections = [
  { title: '재무순위', url: '/jemu' },
  { title: 'IPO', url: '/ipo' },
  { title: '업종현황', url: '/upjong' },
  { title: '테마현황', url: '/theme' },
  { title: '조건검색식', url: '/cond' },
  { title: '키워드검색', url: '/keyword' },
  { title: '실험실', url: '/lab' },
  { title: '방명록', url: '/guestbook' },
  //{ title: '자동매매', url: '/etrade' },
  { title: 'README', url: '/readme' },  
  //{ key: '3', title: '실험실', url: '/lab' },
  //{ key: '4', title: '상세분석', url: '/chart' },
  //{ key: '7', title: '검증의방', url: '/verify' },
  //{ key: '8', title: '로그인', url: '/signin' },
];

function menuClick(key) {
  sessionStorage.setItem('menu', key);
};

function App() {
  let item = sessionStorage.getItem('menu');
  if (item === null) {
    sessionStorage.setItem('menu', '0');
  }

  return (
    <div className="App">
      <Layout className="layout">

        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={sessionStorage.getItem('menu')}>
          {sections.map((item, index) => {
            return <Menu.Item key={index}>
              <Link to={item.url} onClick={() => menuClick(index)} />
              <span>{item.title}</span>
            </Menu.Item>;
          })}
        </Menu>

        <Content style={{ padding: '1px 1px' }}>

          <Routes>
            <Route exact path="/" element={<Jemu/>} />
            <Route exact path="/jemu" element={<Jemu/>} />
            <Route exact path="/ipo" element={<Ipo/>} />
            <Route exact path="/upjong" element={<UpJong/>} />
            <Route exact path="/theme" element={<Theme/>} />
            <Route exact path="/cond" element={<Cond/>} />
            <Route exact path="/keyword" element={<Keyword/>} />
            <Route exact path="/lab" element={<Lab/>} />
            <Route exact path="/account" element={<Account/>} />
            <Route exact path="/guestbook" element={<GuestBook/>} />
            <Route exact path="/readme" element={<Readme/>} />
          </Routes>

        </Content>
        <Footer style={{ textAlign: 'center' }}>VoStock Design ©2019-2022 Created by sunghj0816@gmail.com</Footer>

      </Layout>,
    </div>
  );
}

export default App;
