import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// npm i @ant-design/icons --save
// npm i react-highlight-words --save
// npm i react-iframe --save
// npm i zustand --save
// npm install react-device-detect --save