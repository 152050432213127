import React, { Component, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tag, Table, Alert, Card, Input, Button, Space } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import "antd/dist/antd.css";
import * as common from "./Common";


const Jemu = ({ resetResult, }) => {
  useEffect(() => {
  }, [])

  const [dt, setDt] = React.useState(null);
  const [slist, setSList] = React.useState(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = React.useState(null);
  const [searchedColumn, setSearchedColumn] = React.useState(null);
  const [state, setState] = useState({searchText:'', searchedColumn:''})

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(confirm, clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select()
        )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn:dataIndex,
    });
  };
  
  const handleReset = (confirm, clearFilters) => {
    clearFilters();
    setState({searchText: ''});
    confirm();
  };
  
  React.useEffect(() => {
    axios.get("/api/v3/stock/jemu").then((response) => {
      //console.log(response.data);
      if(response.data) {
        setSList(response.data.list);
        setDt(response.data.dt);
      } else {
        setSList(null);
        setDt(null);
      }
    });
  }, []);

  function makeCondTitle(dt) {
    return "Financial Statements Rank [" + dt + "]";
  }

  //if (!post) return null;

  const columns = [
    {
      title: "종목",
      dataIndex: "name",
      fixed: "left",
      key: "name",
      width:80,
      ...getColumnSearchProps('name'),
      render: (text, record) => <a href={`https://finance.naver.com/item/main.nhn?code=` + record.code} target='_blank' rel='noopener noreferrer nofollow'> {common.makeTableTitle(text, record.sum)}</a>
    },
    {
      title: "코드",
      dataIndex: "code",
      key: "code",
      width:70,
      ...getColumnSearchProps('code'),
      render: (text, record) => common.renderCode(text)
    },
    {
      title: "분기",
      dataIndex: "quarter",
      width:70,
    },
    {
      title: "구분",
      dataIndex: "gubun",
      filters: [
        {
          text: "KQ",
          value: "KQ",
        },
        {
          text: "KS",
          value: "KS",
        },
      ],
      width:70,
      onFilter: (value, record) => record.gubun.indexOf(value) === 0,
    },
    {
      title: "목표주가",
      dataIndex: "destrate",
      width:100,
      align: 'right',
      render: (text, record) => record.destprice ?
        record.destprice + '(' + record.destrate + '%)' :
        ''
    },
    {
      title: '시총(억)',
      dataIndex: 'siga',
      align: 'right',
      sorter: (a, b) => a.siga - b.siga,
      sortDirections: ['descend'],
      width:70,
    },
    {
      title: '배당',
      dataIndex: 'cashrate',
      align: 'right',
      sorter: (a, b) => a.cashrate - b.cashrate,
      sortDirections: ['descend'],
      width:70,
    },
    {
      title: '부채비율',
      dataIndex: 'buche',
      align: 'right',
      sorter: (a, b) => a.buche - b.buche,
      sortDirections: ['ascend'],
      width:70,
    },
    {
      title: '유보율',
      dataIndex: 'ubo',
      align: 'right',
      sorter: (a, b) => a.ubo - b.ubo,
      sortDirections: ['descend'],
      width:70,
    },
    {
      title: '당좌비율',
      dataIndex: 'quick',
      align: 'right',
      sorter: (a, b) => a.ubo - b.ubo,
      sortDirections: ['descend'],
      width:70,
    },
    {
      title: '영업이익율',
      dataIndex: 'youngup',
      align: 'right',
      sorter: (a, b) => a.youngup - b.youngup,
      sortDirections: ['descend'],
      width:80,
    },
    {
      title: '순이익률',
      dataIndex: 'soon',
      align: 'right',
      sorter: (a, b) => a.soon - b.soon,
      sortDirections: ['descend'],
      width:70,
    },
    {
      title: 'PER',
      dataIndex: 'per',
      align: 'right',
      sorter: (a, b) => a.per - b.per,
      sortDirections: ['ascend','descend'],
      width:70,
      render: (text, record) => record.per_toupjong ? <span> {record.per}/{record.per_toupjong} </span> : <span>{record.per}</span> 
    },
    {
      title: 'PBR',
      dataIndex: 'pbr',
      align: 'right',
      sorter: (a, b) => a.pbr - b.pbr,
      sortDirections: ['ascend','descend'],
      width:70,
      render: (text, record) => record.pbr_toupjong ? <span> {record.pbr}/{record.pbr_toupjong} </span> : <span>{record.pbr}</span> 
    },
    {
      title: 'ROE',
      dataIndex: 'roe',
      align: 'right',
      sorter: (a, b) => a.roe - b.roe,
      sortDirections: ['ascend','descend'],
      width:70,
      render: (text, record) => record.roe_toupjong ? <span> {record.roe}/{record.roe_toupjong} </span> : <span>{record.roe}</span> 
    }, 
    {
      title: 'ROA',
      dataIndex: 'roa',
      align: 'right',
      sorter: (a, b) => a.roa - b.roa,
      sortDirections: ['ascend','descend'],
      width:70,
      render: (text, record) => record.roa_toupjong ? <span> {record.roa}/{record.roa_toupjong} </span> : <span>{record.roa}</span>
    },
    {
      title: "실험점수",
      dataIndex: "l_score",
      sorter: (a, b) => a.l_score - b.l_score,
      sortDirections: ['ascend','descend'],
      width:70,
      align: 'right',
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text)}
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "재무점수",
      dataIndex: "j_score",
      align: 'right',
      width:70,
      sorter: (a, b) => a.score - b.score,
      sortDirections: ['ascend','descend'],
      render(text, record) {
        return {
          props: {
            style: { background: common.makeCustomRedColor(text)}
          },
          children: <div>{text}</div>
        };
      }
    },
  ];

  function showExpendedRow(record) {
    return <p style={{ margin: -5 }}> <b>[업종] </b>{record.upjong} <b>[테마] </b>{record.theme}<br/>{record.summary}</p>
  }


  return (
    <div>
      <Alert 
              closable
              style={{ textAlign: "left" }}
              type="info" showIcon 
              message={[
              "각 항목 석차를 계산하여 비율에 대한 점수를 내림차순으로 출력하였습니다. 값이 100에 가까울 수록 좋다는 의미입니다.",
              <br/>,
              "실험점수는 실험실 메뉴에서 좀 더 자세히 확인 할 수 있습니다.",
              <br/>,
              "'종목명'을 클릭하면 Naver증권으로, '종목코드'를 클릭하면 다음차트(모바일에선 네이버차트)로 이동합니다."
              ]}/>
       <Card title={dt !== null ? makeCondTitle(dt[0].dt) : makeCondTitle("")} size="small" bordered={true}>
          <Table
            bordered
            size="small" scroll={{ x: 1024 }}
            rowKey={"code"}
            dataSource={slist}
            columns={columns}
            expandable={{ expandedRowRender: record => 
              showExpendedRow(record),
            }}
            pagination={{ defaultPageSize: common.DEFAULT_PAGE }}
          />
        </Card>
    </div>
  )
}

export default Jemu;
