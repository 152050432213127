import { Tag, Table, Alert, Card, Input, Button, Space, Tooltip, Timeline, } from 'antd';
import "antd/dist/antd.css";

function TimelimeLabelDemo() {
    return (
        <div>
            <Timeline mode="right">
                <Timeline.Item label="2022-03-20">vostock.kr v2.0 리뉴얼</Timeline.Item>
                <Timeline.Item label="2021-08-13">앱버전 출시. #vostock #비오스탁 검색</Timeline.Item>
                <Timeline.Item label="2021-05-18">vostock.kr 리뉴얼</Timeline.Item>
                <Timeline.Item label="2021-02-03">추가개선작업 시작</Timeline.Item>
                <Timeline.Item label="2019-06-13">사이트 재구축</Timeline.Item>
                <Timeline.Item label="2019-03-01" color="red">사이트 구축</Timeline.Item>
            </Timeline>
        </div>
    );
}

function Card1() {
    return (
        <div>
            <Card title="Notice!!" bordered={true} size='default' type="inner" hoverable='true'>
                이곳은 스터디를 위해 개인적으로 진행하고 있는 프로젝트 사이트이며 주식투자 정보를 제공하고 있습니다.<br />
                이 사이트에서 제공하는 정보는 오류가 있을 수 있습니다. 데이터를 그대로 믿지는 마시고, 여러 방면으로 정보를 확인해보시기 바랍니다.<br />
                이 사이트가 여러분들의 주식투자에 조금이나마 도움이 되길 바래봅니다. <br />
                앱 버전도 출시가 되었으니 스토어에서 'vostock' 또는 '비오스탁'으로 검색하시면 앱을 다운 받아 사용할 수 있습니다.<br />
                문의 : sunghj0816@gmail.com<br />
            </Card>
        </div>
    );
}

function Card2() {
    return (
        <div>
            <Card title="History" bordered={true} size='default' type="inner" hoverable='true'>
                <TimelimeLabelDemo></TimelimeLabelDemo>
            </Card>
        </div>
    );
}

function CardGallery() {
    return (
        <div>
            <Card
                title="Random Gallery"
                style={{ margin: 20 }}
                hoverable
                bordered={true} size='small' type="inner"
                cover={<img alt="example" src="https://source.unsplash.com/random" />}
            >
            </Card>
        </div>
    );
}

const Readme = () => {
    return <div>
        <Card1></Card1>
        <br />
        <CardGallery></CardGallery>
    </div>
}

export default Readme;